<template>
  <div class="menu-side-bar" :style="menuExpanded || menuExpanded === '' ? 'width: 260px;' : 'width: 80px;'">
    <div class="flex items-center justify-between px-4 pt-5 h-20" @click="$emit('menuFlag', false)">
      <router-link tag="div" class="cursor-pointer flex items-center h-10" :to="`/${$route.params.lang}/operations/dashboard`">
        <img width="60px" style="min-width: 60px;" class="mr-1" src="@/assets/images/logo.png" />
        <img width="112px" style="min-width: 112px;" class="overflow-hidden" src="@/assets/images/myShipBlu-logo.svg" alt="">
      </router-link>
      <div v-if="windowWidth < 1200">
        <span @click="$emit('menuFlag', false)" class="material-icons text-blue cursor-pointer text-xl">close</span>
      </div>
      <div v-else class="flex items-center overflow-hidden">
        <span v-if="menuExpanded" @click="menuExpanded = !menuExpanded"
          class="material-icons text-blue cursor-pointer text-xl">radio_button_checked</span>
        <span v-else @click="menuExpanded = !menuExpanded"
          class="material-icons text-blue cursor-pointer text-xl">radio_button_unchecked</span>
      </div>
    </div>
    <div class="relative w-full h-full">
      <div class="shadow-bottom"></div>
      <div class="menu-side-bar-items w-full p-4 pt-7" :style="menuExpanded || menuExpanded === '' ? 'width: 260px;' : 'width: 80px;'">
        <div @click="$emit('menuFlag', false)" class="operations-menubar" v-for="(item, index) in navMenuItems" :key="index">
          <router-link :to="`/${$route.params.lang}${item.url}`">
            <div class="flex items-center w-full">
              <p class="material-icons-outlined mr-4">{{ item.icon}}</p>
              <p class="truncate text-sm">{{ item.name }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['navMenuItems', 'windowWidth', 'menuExpanded'],
  data () {
    return {}
  },
  watch: {
    menuExpanded (val) {
      this.$emit('menuExpanded', val)
    }
  }
}
</script>