export default [
  // =============================================================================
  // OPERATIONS LAYOUT MENU
  // =============================================================================
  {
    url: '/operations/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    icon: 'home',
    slug: 'admin-dashboard'
  },
  {
    url: '/operations/received-products',
    name: 'Received Products',
    i18n: 'Received Products',
    icon: 'takeout_dining',
    slug: 'admin-products'
  },
  {
    url: '/operations/outgoing-shipments',
    name: 'Outgoing Shipments',
    i18n: 'Outgoing Shipments',
    icon: 'local_shipping',
    slug: 'admin-shipments'
  },
  {
    url: '/operations/storage-slots',
    name: 'Storage Slots',
    i18n: 'Storage Slots',
    icon: 'dashboard',
    slug: 'admin-dashboard'
  }
]