<template>
  <div>
    <div class="main flex">
      <div :class="(menuFlag || windowWidth >= 1200) ? 'show-menubar' : 'hide-menubar'">
        <div :class="(windowWidth < 1200 && menuFlag && $route.params.lang === 'en') ? 'left-menu-bar-active' : (windowWidth < 1200 && menuFlag && $route.params.lang === 'ar') ? 'right-menu-bar-active' : ''">
          <menubar :navMenuItems="navMenuItems" :menuExpanded="menuExpanded" @menuExpanded="menuExpanded = $event" :windowWidth="windowWidth" @menuFlag="menuFlag = $event" />
        </div>
      </div>
      <div class="ml-auto" 
        :class="(menuExpanded && windowWidth >= 1200) ? 'w-part-page' : !menuExpanded ? 'w-full-page' : 'w-full'">
        <div class="h-6 hide-main"></div>
        <navbar :userData="userData" @menuFlag="menuFlag = $event" />
        <div class="sm:mx-8 mx-5 my-5">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Menubar from './components/Menubar.vue'
import navMenuItems   from './navMenuItems.js'

export default {
  data () {
    return {
      navMenuItems,
      menuExpanded: true,
      userData: {
        po_box: {}
      },
      windowWidth: window.innerWidth,
      menuFlag: false
    }
  },
  watch: {
    windowWidth (val) {
      if (val < 1200) {
        this.menuExpanded = true
        this.menuFlag = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {  
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy () { 
    window.removeEventListener('resize', this.onResize)
  },
  components: {
    Navbar,
    Menubar
  },
  created () {
    this.windowWidth = window.innerWidth
    this.menuFlag = false
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/navbar.scss"></style>