<template>
  <div class="navbar py-4 sm:px-6 px-4 flex items-center xl:justify-end justify-between sm:mx-7 mx-4">
    <div class="xl:hidden flex">
      <img @click="$emit('menuFlag', true)" class="cursor-pointer" width="22px" src="@/assets/images/menu-bar.svg" alt="">
    </div>
    <div class="flex items-center">
      <div class="language-box md:px-4 px-2" @click="changeLocale" :class="$route.params.lang === 'en' ? 'md:py-1 pb-1' : 'py-1 py-1'">
        <p class="md:block hidden font-cairo">Eng / عربى</p>
        <p class="md:hidden block font-cairo">{{$route.params.lang === 'en' ? 'ع' : 'En'}}</p>
      </div>
      <div class="notifications md:ml-3 md:px-3 sm:ml-3 sm:px-3 ml-2 px-2">
        <img class="cursor-pointer" src="@/assets/images/notification-active.svg" alt="">
      </div>
      <div class="flex items-center user-border">
        <p class="text-blue text-sm font-dmSans mr-3">{{ userData.full_name }}</p>
        <button class="profile-img relative">
          <img class="w-8 h-8 cursor-pointer rounded-full" :src="$store.state.AppActiveUser.photoURL" alt="">
          <ul class="dropdown">
            <li class="py-2 px-3 text-xs flex items-center font-medium" @click="logout">
              <img class="rtl-rotate-icon mr-2" src="@/assets/images/logout-icon.svg" alt="">
              <span>{{ $t('Log Out') }}</span>
            </li>
          </ul>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userData'],
  data () {
    return {
      menuFlag: false
    }
  },
  methods: {
    changeLocale () {
      const locale = this.$route.params.lang === 'en' ? 'ar' : 'en'
      this.$cookies.set('locale', locale, '100y')
      localStorage.setItem('locale', locale)
      const dir = this.$cookies.get('locale') === 'en' ? 'ltr' : 'rtl'
      document.documentElement.setAttribute('dir', dir)
      this.$router.push({
        params: {lang: this.$cookies.get('locale')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('locale')
    },
    logout () {
      if (this.$auth.profile) this.$auth.logOut()
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/navbar.scss"></style>